import React, { useEffect } from "react";
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";
import BasicFormField from "@abl/components/src/BasicFormField/BasicFormField";
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';
import { connect } from "formik";
import PropTypes from 'prop-types';

const StepThree = (props) => {
  const { schoolFTE, schoolClassSize, totalStudentEnrollment } = props.formik.values;
  let tabIndex = 10;


  useResizeHelper(false);

  useEffect(() => {
    props.formik.setFieldValue("modifiableFTE", schoolFTE);
    props.formik.setFieldValue("modifiableClassSize", schoolClassSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolFTE, schoolClassSize]);

  useEffect(() => {
    var buttons = document.getElementById("next_btn");
    let { totalStudentEnrollment, schoolFTE, schoolClassSize } = props.formik.values.errors;
    
    if (totalStudentEnrollment || schoolFTE || schoolClassSize || props.formik.values.totalStudentEnrollment === '' || props.formik.values.schoolFTE === ''
      || props.formik.values.schoolClassSize === '') {
      buttons.classList.add("disabled");
    }
    else {
      buttons.classList.remove("disabled");
    }
  }, [props.formik.values]);

  return (
    <div className="stepthree">
      <div className="centered_form mt-4 reduced-width">
        <div className={totalStudentEnrollment === '' ? 'align-text-left' : ''}>
          <BasicFormField
            tabIndex={tabIndex++}
            fieldName='totalStudentEnrollment'
            labelText='HOW MANY STUDENTS ARE ENROLLED IN YOUR SCHOOL?'
            backgroundText={totalStudentEnrollment === '' ? 'Enter # of students' : (`student${totalStudentEnrollment === '1' ? '' : 's' }`)}
            type='text'
            optional="true"
            displayOptional="false"
            errorMsg="Must be between 1 and 1,000,000"
            minValue={1}
            maxValue={1000000}
            onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
          />
        </div>
        <div className={schoolFTE === '' ? 'align-text-left' : ''}>
          <BasicFormField
          tabIndex={tabIndex++}
            fieldName='schoolFTE'
            labelText='HOW MANY REGULAR TEACHER FTE AT YOUR SCHOOL?'
            backgroundText={schoolFTE === '' ? 'Enter # of teachers' :'FTE'}
            type='text'
            extraInfo='*FTE is Full Time Employee'
            optional="true"
            displayOptional="false"
            errorMsg="Must be between 1 and 50,000"
            minValue={1}
            maxValue={50000}
            onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
          />
        </div>
        <div className={schoolClassSize === '' ? 'align-text-left' : ''}>
          <BasicFormField
            tabIndex={tabIndex++}
            fieldName='schoolClassSize'
            labelText='WHAT IS YOUR TARGET CLASS SIZE?'
            backgroundText={schoolClassSize === '' ? 'Enter target class size ' : (`student${schoolClassSize === '1' ? '' : 's' }`)}
            type='text'
            optional="true"
            displayOptional="false"
            errorMsg="Must be between 1 and 200"
            minValue={1}
            maxValue={200}
            onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
          />
        </div>
      </div>
      <p className="font-weight-bold text-align-center mx-auto final_text">
        Click &apos;next&apos; to see the impact of different base schedules on class size and teachers needed for enrollment.
      </p>
      <PageChangeButtons
      tabIndex={tabIndex}
        backRoute="/wizard/two"
        nextRoute="/wizard/three"
        nextOnClick={(event) => {
          event.preventDefault();
          props.formik.handleSubmit();
        }}
      />
    </div>
  );
}

StepThree.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default connect(StepThree);
