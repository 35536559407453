import React, { useState, useEffect } from 'react';
import OptimizerPDFView from '../../components/OptimizerPDFView/OptimizerPDFView';
import API from '@abl/components/src/api/api';

const Pdf = () => {
  const [data, setData] = useState({res: {}, fetching: true});
  useEffect(() => {
    const getdata = async() => {
      // eslint-disable-next-line no-return-await
      console.log(window.location)
      const search = window.location.search.split(/[=&?]/);
      const id = search.indexOf('id');
      const stepperSelected = search.indexOf('stepperSelected');

      console.log(window.location.query)
      const response = await (await API.get('resources/' + search[id+1])).data;
      setData({res: response, fetching: false, stepperSelected: search[stepperSelected+1]});
    };

    if(data.fetching)getdata();
  }, [data]);

  return (
    <div>
      {data.fetching ? <div>Wait...</div> : <OptimizerPDFView data={data.res} stepperSelected={data.stepperSelected} />}
    </div>
  );
}

export default Pdf;
