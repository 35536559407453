import React from 'react';
import './PDFHeader.scss';
import PropTypes from 'prop-types';

// eslint-disable-next-line max-params
const PDFHeader = props => {
  const {totalStudentEnrollment, classesPerTeacher, classesPerStudent, schoolFTE, schoolClassSize, stepperSelected} = props;
    return (
      <div className="pdfheader">
        <div className="your_school_title">
          YOUR SCHOOL CURRENTLY
        </div>
        <div className="your_school_info_container">
            
            <div className="row your_school_info_row">
              <div className="your_school_info_box flex-fill with_border w-22 center_text">
                <div className="text-align-center">
                  <div className="big_number">
                    {totalStudentEnrollment}
                  </div>
                  <div className="text_tag">
                    students
                  </div>
                </div>
                
              </div>
              <div className="your_school_info_box flex-fill with_border w-26 center_text">
                <div className="text-align-center">
                  <div className="big_number">
                    {`${classesPerTeacher} of ${classesPerStudent}`}
                  </div>
                  
                  <div className="text_tag">
                      base schedule
                  </div>
                </div>
              </div>
              <div className="your_school_info_box flex-fill with_border w-26 center_text">
                <div className="text-align-center">
                  <div className="big_number">
                    {schoolClassSize}
                  </div>
                  
                  <div className="text_tag">
                    avg students/class
                  </div>
                </div>
              </div>
              <div className="your_school_info_box flex-fill w-26 center_text">
                <div className="text-align-center">
                  <div className="big_number">
                    {schoolFTE}
                  </div>
                  
                  <div className="text_tag">
                    FTE
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="row">
          <div className="w-48 base_schedule_title">
            BASE SCHEDULE
          </div>
          <div className="w-26 class_size_title">
            CLASS SIZE {stepperSelected === "FTEstepperSelected"? "NEEDED" : ""}
          </div>
          <div className="w-26 fte_title">
            FTE {stepperSelected === "ClassSizestepperSelected"? "NEEDED" : ""}
          </div>
        </div>
        <div className="line">

        </div>
          
      </div>
    );
}

PDFHeader.propTypes = {
  classesPerTeacher: PropTypes.number.isRequired, 
  classesPerStudent: PropTypes.number.isRequired,
  totalStudentEnrollment: PropTypes.number.isRequired,
  schoolClassSize:PropTypes.number.isRequired,
  schoolFTE:PropTypes.number.isRequired,
  stepperSelected:PropTypes.string.isRequired
};

export default PDFHeader;
