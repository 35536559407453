import React, { Component } from "react";
import { Switch, Route, Prompt, Redirect, matchPath } from "react-router-dom";
import { Formik, Form } from "formik";
import API from '@abl/components/src/api/api';
import PropTypes from 'prop-types';
import { withStore } from '../../store';

import StepOne from "./steps/one";
import StepTwo from "./steps/two";
import StepThree from "./steps/three";
import Optimizer from "../optimizer";

import './index.scss';

class Wizard extends Component {

  constructor (props){
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleSubmit=this.handleSubmit.bind(this);
    this.removeDuplicates=this.removeDuplicates.bind(this);
  }
  
  removeDuplicates(array) {
    let a = [];
    array.map(x => {
      if(!a.includes(x.join())) {
        a.push(x.join())
      }
      
      return x;
    });
    return a;
  };

  async handleSubmit (values) {
    const {history, store} = this.props;
    const { scheduleOptions, classesPerStudent, classesPerTeacher } = values;

    try{

      values.totalStudentEnrollment = Number(values.totalStudentEnrollment.toString().split(',').join(''));
      values.modifiableFTE =Number(values.modifiableFTE.toString().split(',').join(''));
      values.schoolFTE = Number(values.schoolFTE.toString().split(',').join(''));

      const comparedSchedules = [];

      // Getting schedules numbers
      let schedules = scheduleOptions.map((schedule) => {
        schedule.selected=false;
        const classes = schedule.name.split(' of ');
        for(let x = 0; x < classes.length; x++) classes[x] = Number(classes[x]);

        return classes;
      });

      // Adding user schedule
      schedules.push([Number(classesPerTeacher), Number(classesPerStudent)]);

      //
      schedules.sort((a, b) => {
        if(a[1]-b[1] === 0){
          return a[0]-b[0];
        }

        return a[1]-b[1];
      });

      // Deletes repeated schedules if exist
      schedules = this.removeDuplicates(schedules);

      console.log(schedules);

      schedules.map((schedule, index) => {
        const classes = schedule.split(',');

        if(Number(classes[0]) === Number(classesPerTeacher)){
          if (Number(classes[1]) === Number(classesPerStudent)) {
            if(index === 0){
              comparedSchedules.push(schedules[index + 1].split(',').join('-'));
            }

            else if(index === schedules.length - 1){
              comparedSchedules.push(schedules[index - 1].split(',').join('-'));
            }

            else {
              comparedSchedules.push(schedules[index - 1].split(',').join('-'));
              comparedSchedules.push(schedules[index + 1].split(',').join('-'));
            }
          }
        }

        return classes;
      });

      values.comparedSchedules = comparedSchedules;

    let response = await API.post('resources', values);

    store.set('resources', response.data);
    localStorage.setItem('resources', response.data.id);
    this.setState(
      {
        error: null,
        submitted: true,
      },
      () => history.push("/wizard/optimizer"),
    );
    } catch (error){
      console.log(error)
      this.setState({error})
    }
  };

  



  render() {
    const {submitted, error} = this.state;

    return (
      <div className="wizard">
        { error && (
          <div className="alert alert-danger">
            Some error occurred
          </div>
        )}
        <Prompt
          when={!submitted}
          message={({ pathname }) => {
            return matchPath(pathname, { path: "/wizard" })
              ? true
              : "Are you sure you want to navigate away?";
          }}
        />
        <Formik
          initialValues={{
            operatingSystem: '',
            classesPerTeacher: '',         
            classesPerStudent: '',
            totalStudentEnrollment: '',
            modifiableFTE: 1,
            modifiableClassSize: 1,
            schoolFTE: '',
            schoolClassSize: '',
            stepperSelected: "ClassSizestepperSelected", // 0 if none is selected, "FTEstepperSelected" if Ajust FTE stepper is  being modified, "ClassSizestepperSelected" if Ajust Class Size stepper is being modified.
            pressingTimer: 0, // Handles the stepper timer when not being pressed for 1 second.
            schools: [],
            state:"",
            schoolName:"",
            firstName:"",
            lastName:"",
            role:"",
            email:"",
            gsid:"",
            city:"",
            readyToExport: false,
            selectedSchedules: [],
            scheduleOptions: [
              {
                name:"3 of 4",
                selected:false,
                index: 0
              },
              {
                name:"4 of 5",
                selected:false,
                index: 1
              },
              {
                name:"3 of 6",
                selected:false,
                index: 2
              },
              {
                name:"4 of 6",
                selected:false,
                index: 3
              },
              {
                name:"5 of 6",
                selected:false,
                index: 4
              },
              {
                name:"5 of 7",
                selected:false,
                index: 5
              },
              {
                name:"6 of 7",
                selected:false,
                index: 6
              },
              {
                name:"5 of 8",
                selected:false,
                index: 7
              },
              {
                name:"6 of 8",
                selected:false,
                index: 8
              },
              {
                name:"7 of 8",
                selected:false,
                index: 9
              },
              {
                name:"7 of 9",
                selected:false,
                index: 10
              },
              {
                name:"8 of 9",
                selected:false,
                index: 11
              },
            ],
            errors: {}
          }}
          onSubmit={ this.handleSubmit }
        >
          <Form>
            <Switch>
              <Route path="/wizard/one" component={StepOne} />
              <Route path="/wizard/two" component={StepTwo} />
              <Route path="/wizard/three" component={StepThree} />
              <Route path="/wizard/optimizer" component={Optimizer} />
              <Redirect exact from="/wizard" to="/wizard/one" />
            </Switch>
          </Form>
        </Formik>
      </div>
    );
  }
}

Wizard.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStore(Wizard);
