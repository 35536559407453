import React from 'react';
import './OptimizerBoard.scss';
import PropTypes from 'prop-types';
import { connect } from "formik";
import ScheduleRow from '../../components/ScheduleRow/ScheduleRow';
import CompareScheduleDropdown from '../CompareScheduleDropdown/CompareScheduleDropdown';

const OptimizerBoard = props => {

    const {selectedSchedules, totalStudentEnrollment, modifiableClassSize, modifiableFTE, stepperSelected, schoolFTE, schoolClassSize  } = props.formik.values;

    
    return (
        <div id="optimizerboard" className="optimizerboard">
            <div className="board">
                
                <div className="center_container">
                    
                    <div className="rows">
                        {selectedSchedules.filter(schedule => schedule.selected).map((schedule) =>
                            {
                                let scheduleData = schedule.name.split(" of ");
                                let scheduleClassesPerTeacher = scheduleData[0];
                                let scheduleClassesPerStudent = scheduleData[1];
                                return <div key={"scheduleSelectedOption_"+schedule.name} className="centeritem">

                                            <ScheduleRow 
                                                index={schedule.index} 
                                                classesPerTeacher={Number(scheduleClassesPerTeacher)} 
                                                classesPerStudent={Number(scheduleClassesPerStudent)} 
                                                totalStudentEnrollment={Number(totalStudentEnrollment)}
                                                modifiableClassSize={Number(modifiableClassSize)}
                                                modifiableFTE={Number(modifiableFTE)}
                                                stepperSelected={stepperSelected}
                                                schoolFTE={Number(schoolFTE)}
                                                schoolClassSize={Number(schoolClassSize)}
                                            />    
                                        </div>
                            }
                        )
                        }
                    </div>
                    <CompareScheduleDropdown />
                </div>
                
            </div>
        </div>
    );
}

OptimizerBoard.propTypes = {
    formik: PropTypes.object.isRequired
};

export default connect(OptimizerBoard);
