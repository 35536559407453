import React from 'react';
import './OptimizerPDFView.scss';
import ScheduleRow from '../../components/ScheduleRow/ScheduleRow';
import { isPromise } from "formik";
import PDFHeader from '../PDFHeader/PDFHeader';
import PropTypes from 'prop-types';
import ABLImg from '@abl/components/src/assets/Abl_Logo.png';
import UnlockingImg from '@abl/components/src/assets/UT_Logo.png';


const OptimizerPDFView = ({data, stepperSelected}) => {
  
  while(isPromise(data));


  const {modifiableFTE, modifiableClassSize, classesPerTeacher, classesPerStudent, schoolClassSize, schoolFTE, totalStudentEnrollment} = data;
  let {comparedSchedules} = data;

  comparedSchedules = (comparedSchedules !== null && comparedSchedules !== undefined)? comparedSchedules.map(schedule => schedule.split("-").join(" of ")) : [];
  comparedSchedules.unshift(`${classesPerTeacher} of ${classesPerStudent}`);
  
  const GetPages = () => {
    let pages = [];

    let pageNum = Math.ceil( comparedSchedules.length / 6 );

    for (let index = 0; index < pageNum; index++) {
      pages.push(
        <div className="pdf_page">
        <div className="pdf_header">
          <div className="header_big_text">
            BASE SCHEDULE COMPARISON
          </div>
          <div className="header_medium_text">
            Following is a summary of the base schedules you chose to compare.
          </div>
          <div className="header_small_text">
            The base schedule is a teacher-centric view of a school schedule that considers the percent of time teachers are teaching students.
          </div>
          <div className="header_small_text">
            Schools can tradeoff class size or the number of full time employees they have depending upon which base schedule they choose.
          </div>
        </div>
        <div className="board">
          
          <div className="center_container">
              
              <PDFHeader 
                classesPerTeacher={classesPerTeacher} 
                classesPerStudent={classesPerStudent} 
                schoolClassSize={schoolClassSize} 
                schoolFTE={schoolFTE} 
                totalStudentEnrollment={totalStudentEnrollment}
                stepperSelected={stepperSelected}
                />
              <div className="rows">
                  {comparedSchedules.slice(index * 6, (index + 1) * 6).map((schedule, index) =>
                      {
                          let scheduleData = schedule.split(" of ");
                          let scheduleClassesPerTeacher = scheduleData[0];
                          let scheduleClassesPerStudent = scheduleData[1];
                          return  <div key={"scheduleSelectedOption_"+schedule} className="centeritem">
                                      <ScheduleRow  index={-1} 
                                                    stepperSelected={stepperSelected}
                                                    classesPerTeacher={Number(scheduleClassesPerTeacher)} 
                                                    classesPerStudent={Number(scheduleClassesPerStudent)} 
                                                    modifiableFTE={Number(modifiableFTE)} 
                                                    modifiableClassSize={Number(modifiableClassSize)} 
                                                    schoolFTE={Number(schoolFTE)} 
                                                    schoolClassSize={Number(schoolClassSize)}   
                                                    totalStudentEnrollment={Number(totalStudentEnrollment)} 
                                                    numberEasingDuration={0} 
                                                    isPDFView="true"
                                                    />    

                                  </div>
                      }
                  )
                  }
              </div>
          </div>
          
        </div>
        <div className="pdf_footer">
          <div className="row">
            <div className="unlocking">
              <div>
                <img  src={UnlockingImg} alt="" />
              </div>
              
              <div>
                <a href='https://unlockingtime.org/' className="page link-pointer ">UnlockingTime.org</a>
              </div>
              
            </div>
            <div className="abl">
              <div>
                <img src={ABLImg} alt="" />
              </div>
              <div>
                <a href='https://www.ablschools.com/' className="page link-pointer">Ablschools.com</a>
              </div>               
            </div>
          </div>
        </div>
        <div className="page_break">

        </div>
        </div>
    );
    }

    
    
    return pages;
  }

  return (
    <div className="optimizerpdfview">
      {GetPages()}
    </div>
  );
}

OptimizerPDFView.propTypes = {
  data: PropTypes.object.isRequired,
  stepperSelected:PropTypes.string.isRequired,
  
};

export default OptimizerPDFView;