import React from 'react';
import './InformativeBox.scss';
import PropTypes from 'prop-types';

class InformativeBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      classesPerTeacher: props.classesPerTeacher || 0,
      classesPerStudent: props.classesPerStudent || 0,
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
   
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        classesPerTeacher: this.props.classesPerTeacher,
        classesPerStudent: this.props.classesPerStudent,
      });
  }

  render() {
    
    return <div className="informativebox row">                
                    <div className="col left-text-properties">
                      <div className="row youhave-text-properties"> You have a</div> 
                      <div className="row centeritems">
                        <div className="numbers-text-properties">{this.state.classesPerTeacher} </div> 
                        <div className="of-text-properties"> of </div>  
                        <div className="numbers-text-properties">{this.state.classesPerStudent} </div> 
                      </div> 
                      <div className="row baseschedule-text-properties">Base Schedule</div> 
                    </div>
                    <div className="col right-text-properties">
                      <div  className="darkerbluebackground">
                        <div><b>Base Schedule vs. Bell Schedule</b></div>
                        <div><i>What&apos;s the difference?</i></div>               
                      </div>
                      <div  className="lighterbluebackground">
                        <div>
                          <div> <b> <u>BASE SCHEDULE</u> </b> </div>
                          <div>A teacher-centric view of scheduling 
                              that considers the <b> amount of time 
                              teachers are teaching students.</b> 
                           </div> 
                        </div>    
                        <br />
                        <div>
                          <div> <b> <u>BELL SCHEDULE</u> </b> </div>
                          <div>A student-centric view of  
                          scheduling that considers 
                          <b> when students are in classes.</b>
                          </div> 
                        </div> 

                      </div>
                    </div>
          </div>
  }
}

InformativeBox.propTypes = {
  classesPerTeacher: PropTypes.number.isRequired,
  classesPerStudent: PropTypes.number.isRequired
};

export default InformativeBox;
