import React from 'react';
import { connect } from "formik";
import './ComparisonHeader.scss';
import PropTypes from 'prop-types';
import AdjustComponent from '../../components/AdjustComponent/AdjustComponent';
import ScheduleRow from '../../components/ScheduleRow/ScheduleRow';

const ComparisonHeader = (props) => {
  const {modifiableFTE, modifiableClassSize, classesPerTeacher, classesPerStudent, totalStudentEnrollment, schoolFTE, schoolClassSize, stepperSelected, pressingTimer} = props.formik.values;
  return (
    <div className="comparison-header d-flex flex-column">
      <div className="d-inline-flex mx-auto my-1">
        <div className="pl-4 base-schedule align-self-end">BASE SCHEDULE</div>
        <AdjustComponent text="Adjust FTE" value={Number(modifiableFTE)} name="modifiableFTE" stepperSelected="FTEstepperSelected" max={50000} pressingTimer={pressingTimer} />
        <AdjustComponent text="Adjust Class Size" value={Number(modifiableClassSize)} name="modifiableClassSize" stepperSelected="ClassSizestepperSelected" max={200}  pressingTimer={pressingTimer} />
      </div>
      <div className="main_row mx-auto">
        <div className='d-inline-flex school-info'>
          <div className="school-students py-1">{`YOUR SCHOOL: ${totalStudentEnrollment} students`} </div>
          <div className={`row class_fte py-1 ${props.formik.values.stepperSelected === "FTEstepperSelected" ? 'focus-middle' : ''}`}>
            avg <div className="bold_number">{schoolClassSize}</div> students/class
          </div>
          <div className={`row class_fte py-1 ${props.formik.values.stepperSelected === "ClassSizestepperSelected" ? 'focus-middle' : ''}`}>
            <div className="bold_number">{schoolFTE}</div> FTE
          </div>
        </div>
        <div className="base_schedule">
            <ScheduleRow 
              index={-1} 
              classesPerTeacher={Number(classesPerTeacher)} 
              classesPerStudent={Number(classesPerStudent)} 
              schoolFTE={Number(schoolFTE)} 
              schoolClassSize={Number(schoolClassSize)} 
              description="true" 
              stepperSelected={stepperSelected}
              totalStudentEnrollment={Number(totalStudentEnrollment)}
              modifiableClassSize={Number(modifiableClassSize)}
              modifiableFTE={Number(modifiableFTE)}

              
            />    
        </div>
      </div>
      
    </div>
  )
};

ComparisonHeader.propTypes = {
  formik: PropTypes.object.isRequired
}

export default connect(ComparisonHeader);