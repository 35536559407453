import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import OptimizerPDF from "./pages/pdf";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Wizard from './pages/wizard/';
import "classlist-polyfill"
import './App.scss';
import {createStore} from './store';

const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="container-fluid">
          <div>
            <Switch>
              <Route path="/wizard" component={Wizard} />
              <Route path="/pdf" component={OptimizerPDF} />
              <Redirect exact from="/" to="/wizard" />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default createStore(App);
