import React from 'react';
import './CompareScheduleDropdown.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import onClickOutside from "react-onclickoutside";
import { connect } from "formik";
import PropTypes from 'prop-types';
import API from '@abl/components/src/api/api';

const CompareScheduleDropdown = props => {
  const {scheduleOptions, classesPerTeacher, classesPerStudent, selectedSchedules} = props.formik.values;
  const baseScheduleName = `${classesPerTeacher} of ${classesPerStudent}`


 

    const OpenCompareDropdown = () => {
      let container = window.document.getElementById("options_container");
      if(container.classList.contains("options_container_active")){
        container.classList.remove("options_container_active");
      }
      else{
        container.classList.add("options_container_active");
      }

      let btn = window.document.getElementById("options_btn");

      if(btn.classList.contains("options_btn_active")){
        btn.classList.remove("options_btn_active");
      }
      else{
        btn.classList.add("options_btn_active");
      }
    }

    const ScheduleSelected = async index => {
      scheduleOptions[index].selected = true;
      props.formik.setFieldValue(`scheduleOptions[${index}]`,scheduleOptions[index]);
      
      selectedSchedules.push(scheduleOptions[index]);

      props.formik.setFieldValue('selectedSchedules', selectedSchedules);

      let id = localStorage.getItem('resources');


      await API.put(`resources/${id}`, {
        id: id,
        "comparedSchedules": props.formik.values.selectedSchedules.map(option => option.name.split(" of ").join("-"))
      });

      var container = document.getElementById("options_container");
      if(container.classList.contains("options_container_active")){
        container.classList.remove("options_container_active");
      }

      console.log("resource updated");
    }

    return (
      <div className="comparescheduledropdown">
          <FunctionalButton 
          id = "options_btn"
          className="compare_dropdown"
          text="Compare another base schedule"
          onclickhandler={OpenCompareDropdown}
          />

          <div id="options_container" className="options_container">
            {scheduleOptions.filter(schedule => !schedule.selected && schedule.name !== baseScheduleName).map((schedule) => {
              return  <div key={schedule.name} className="schedule_option" onClick={() => ScheduleSelected(schedule.index)}>
                        {schedule.name}
                      </div>
            })}

          </div>
        </div>
    );
}

CompareScheduleDropdown.propTypes = {
  formik: PropTypes.object.isRequired,
};




export default onClickOutside(connect(CompareScheduleDropdown), {
	handleClickOutside: function(instance){
	  return function(e) {
		  var container = document.getElementById("options_container");
      if(container.classList.contains("options_container_active")){
        container.classList.remove("options_container_active");
      }
      
	  };
	}
})