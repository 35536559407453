import React from 'react'
import './TeacherStudentRelation.scss';
import PropTypes from 'prop-types';

const TeacherStudentRelation = ({classesPerTeacher, classesPerStudent, teachingTime, stepperSelected}) => {
  


  const boxes = [];
  for(let i = 0; i < classesPerStudent; i++){
    boxes.push(
      <div key={"classesPerStudent/"+i.toString()} className="padding">
        <div className={`box ${i<classesPerTeacher ? 'fill' : ''}`}></div>
      </div>
    );
  }


 
  function modifyClassName() {
     
    let borderStyle ={};
    
    if (stepperSelected==="FTEstepperSelected") {
      borderStyle= {}
    }
    else{
      borderStyle= {
        borderRight: "1px solid #BFBFBF"
      }
    }

    return borderStyle;
  }
 



  return (
    <div  className="teacher-student" style={modifyClassName()}>
      <div className="row font-weight-bold row-title">{`${classesPerTeacher} of ${classesPerStudent}`}</div>
      <div className="row">
        <div className="col d-flex flex-wrap align-items-center">
          {boxes.map(box => box)}
        </div>
        <div className="col d-flex element-padding ">
          <div className="font-weight-bold percent_text">
            {`${teachingTime === Math.ceil(teachingTime) ? teachingTime : teachingTime.toFixed(1)}%`}
          </div>
          <div className="font-italic align-self-center percent_sub_text">Teaching Time</div>
        </div>
      </div>
    </div>
  )
}

TeacherStudentRelation.propTypes = {
  classesPerTeacher: PropTypes.number.isRequired,
  classesPerStudent: PropTypes.number.isRequired,
  teachingTime: PropTypes.number.isRequired,
  stepperSelected: PropTypes.string.isRequired
};

export default TeacherStudentRelation;
