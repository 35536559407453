import React from 'react';
import './OptimizerHeader.scss';
import PropTypes from 'prop-types';
import { connect } from "formik";
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import ExportImage from '../../assets/export_schedule_icon.svg';
import LinkerButton from '@abl/components/src/LinkerButton/LinkerButton';


const OptimizerHeader = () => {

  const ExportSchedule = () => {
    var form = document.getElementById("exportform");
    form.classList.add("active");
  }

    return (
      <div className="optimizerheader">
            <div className="row">
                <div className="col-sm-6 left_column">
                  <div className="title_container">
                    <div className="title">
                      Compare Base Schedules
                    </div>
                    <LinkerButton className="back_link" toRoute="/wizard/three" text="< Back to Settings" />
                  
                  </div>
                  
                </div>

                <div className="col-sm-6 right_column">
                    <FunctionalButton imgpath = {ExportImage} className="export_button button_type_a" text="Export Report" onclickhandler={ExportSchedule} />
                </div>

            </div>
        </div>
    );
}

OptimizerHeader.propTypes = {
  formik: PropTypes.object.isRequired
};

export default connect(OptimizerHeader);
