import React from 'react';
import './ScheduleRow.scss';
import PropTypes from 'prop-types';
import { connect } from "formik";
import TeacherStudentRelation from '../TeacherStudentRelation/TeacherStudentRelation';
import ClassSizeFTE from '../ClassSizeFTE/ClassSizeFTE';
import API from '@abl/components/src/api/api';

  
 class ScheduleRow extends React.PureComponent {


  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      formik: props.formik,
      classesPerTeacher: props.classesPerTeacher,
      classesPerStudent: props.classesPerStudent,
      totalStudentEnrollment:props.totalStudentEnrollment,
      modifiableClassSize:props.modifiableClassSize,
      modifiableFTE:props.modifiableFTE,
      schoolFTE: props.schoolFTE,
      schoolClassSize: props.schoolClassSize,
      stepperSelected:props.stepperSelected,
      index: props.index,
      description: props.description,
      numberEasingDuration: props.numberEasingDuration,
      isPDFView: props.isPDFView,
    }
    
  }



  componentDidUpdate(prevProps, prevState) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        formik: this.props.formik,
        classesPerTeacher: this.props.classesPerTeacher,
        classesPerStudent: this.props.classesPerStudent,
        totalStudentEnrollment:this.props.totalStudentEnrollment,
        modifiableClassSize:this.props.modifiableClassSize,
        modifiableFTE:this.props.modifiableFTE,
        schoolFTE: this.props.schoolFTE,
        schoolClassSize: this.props.schoolClassSize,
        stepperSelected:this.props.stepperSelected,
        index: this.props.index,
        description: this.props.description,
        numberEasingDuration: this.props.numberEasingDuration,
        isPDFView: this.props.isPDFView
      });
  }

  


  render() {

    let find = (list, element) => {
      const count = list.length;
      for (let i = 0; i < count; i++){
        if(list[i].name === element.name){
          return i;
        }
      }
      
      return -1;
    }
    
    let DeleteRow = async (index) => {

      let option = this.state.formik.values.scheduleOptions[index];
      const { selectedSchedules } = this.state.formik.values;
      option.selected = false;
      this.state.formik.setFieldValue(`scheduleOptions[${index}]`, option);



      selectedSchedules.splice(find(selectedSchedules, option), 1);

      this.state.formik.setFieldValue('selectedSchedules', selectedSchedules);

      let id = localStorage.getItem('resources');


      await API.put(`resources/${id}`, {
        id: id,
        "comparedSchedules": this.state.formik.values.selectedSchedules.map(option => option.name.split(" of ").join("-"))
      });

      console.log("resource updated");
    }

    let timeToTeach=(Number(this.state.classesPerTeacher) / Number(this.state.classesPerStudent)) ;
    let classSize = (Number(this.state.totalStudentEnrollment)/(Number(this.state.modifiableFTE) * timeToTeach));
    let FTE= (Number(this.state.totalStudentEnrollment)/Number(this.state.modifiableClassSize)) / timeToTeach;
    
    

    return <div className="schedulerow"> 
          
          <div className="row">
            <div className="delete_btn" onClick={() => DeleteRow(this.state.index)}>
              x
            </div>
            <TeacherStudentRelation classesPerTeacher={this.state.classesPerTeacher} classesPerStudent={this.state.classesPerStudent} teachingTime={timeToTeach*100} stepperSelected={this.state.stepperSelected} />
            <div className={
                (this.state.isPDFView === "true")? 
                  'focus-middle class_size_box'
                :
                (this.state.stepperSelected === "FTEstepperSelected") ? 'focus-middle class_size_box' : 'w-23 class_size_box'                             
                }>
              {(this.state.stepperSelected === "ClassSizestepperSelected" )?
                  <ClassSizeFTE  
                      currentClassSize={Number(this.state.modifiableClassSize)} 
                      currentFTE={Math.ceil(FTE)} 
                      selected={(this.state.isPDFView === "true")?"true" : "false"} 
                      description={this.state.description} 
                      columnPosition="left"  
                      schoolFTE={this.state.schoolFTE} 
                      schoolClassSize={this.state.schoolClassSize} 
                      modifiableClassSize={this.state.modifiableClassSize}
                      modifiableFTE={this.state.modifiableFTE}
                      stepperSelected={this.state.stepperSelected}
                      numberEasingDuration = {this.state.numberEasingDuration}    
                      isPDFView={this.state.isPDFView}                          

                      />
              :
                (this.state.stepperSelected === "FTEstepperSelected"  )?
                  <ClassSizeFTE  
                      currentClassSize={Math.ceil(classSize)} 
                      currentFTE={Number(this.state.modifiableFTE)} 
                      selected="true" 
                      description={this.state.description}
                      columnPosition="left" 
                      schoolFTE={this.state.schoolFTE} 
                      schoolClassSize={this.state.schoolClassSize} 
                      modifiableClassSize={this.state.modifiableClassSize}
                      modifiableFTE={this.state.modifiableFTE}
                      stepperSelected={this.state.stepperSelected}
                      numberEasingDuration = {this.state.numberEasingDuration}     
                      isPDFView={this.state.isPDFView}                         

                      />
                :
                <div></div>
              }
              
            </div>
            <div className={
              (this.state.isPDFView  === "true" )? 
                'focus-middle fte_box'
              :
                (this.state.stepperSelected === "ClassSizestepperSelected") ? 'focus-middle fte_box' : 'w-23 fte_box'
              }>
              
              {(this.state.stepperSelected === "ClassSizestepperSelected")?
                  <ClassSizeFTE  
                      currentFTE={Math.ceil(FTE)} 
                      currentClassSize={Number(this.state.modifiableClassSize)} 
                      selected="true" 
                      description={this.state.description} 
                      columnPosition="right" 
                      schoolFTE={this.state.schoolFTE} 
                      schoolClassSize={this.state.schoolClassSize} 
                      modifiableClassSize={this.state.modifiableClassSize}  
                      modifiableFTE={this.state.modifiableFTE} 
                      stepperSelected={this.state.stepperSelected}      
                      numberEasingDuration = {this.state.numberEasingDuration}       
                      isPDFView={this.state.isPDFView}                       
                      />
              :
                (this.state.stepperSelected === "FTEstepperSelected" )?
                  <ClassSizeFTE  
                    currentFTE={Number(this.state.modifiableFTE)} 
                    currentClassSize={Math.ceil(classSize)} 
                    selected={(this.state.isPDFView  === "true" )?"true" : "false"} 
                    description={this.state.description} 
                    columnPosition="right"  
                    schoolFTE={this.state.schoolFTE} 
                    schoolClassSize={this.state.schoolClassSize}
                    modifiableClassSize={this.state.modifiableClassSize}
                    modifiableFTE={this.state.modifiableFTE}
                    stepperSelected={this.state.stepperSelected}
                    numberEasingDuration = {this.state.numberEasingDuration}
                    isPDFView={this.state.isPDFView}
                     />
                :
                <div></div>
              }
            </div>

          </div>




        </div>
  }
}

ScheduleRow.defaultProps = {
  description: "",
  numberEasingDuration: 1000,
  isPDFView: "false"
};


ScheduleRow.propTypes = {
  formik: PropTypes.object.isRequired,
  classesPerTeacher: PropTypes.number.isRequired, 
  classesPerStudent: PropTypes.number.isRequired,
  totalStudentEnrollment: PropTypes.number.isRequired,
  modifiableClassSize:PropTypes.number.isRequired,
  modifiableFTE:PropTypes.number.isRequired,
  schoolClassSize:PropTypes.number.isRequired,
  schoolFTE:PropTypes.number.isRequired,
  stepperSelected:PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  description: PropTypes.string,
  numberEasingDuration: PropTypes.number,
  isPDFView: PropTypes.string
};

export default connect(ScheduleRow);