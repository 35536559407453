import React , {useEffect}  from 'react';
import './index.scss';
import OptimizerHeader from '../../components/OptimizerHeader/OptimizerHeader';
import OptimizerBoard from '../../components/OptimizerBoard/OptimizerBoard';
import ExportForm from '@abl/components/src/ExportForm/ExportForm';
import ThankYouWindow from '@abl/components/src/ThankYouWindow/ThankYouWindow';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import ExportImage from '../../assets/export_schedule_icon.svg';
import API from '@abl/components/src/api/api';
import { connect } from "formik";
import PropTypes from 'prop-types';
import ComparisonHeader from '../../components/ComparisonHeader/ComparisonHeader';
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';

const Optimizer = (props) => {

  let {scheduleOptions, readyToExport, operatingSystem} = props.formik.values;

  useResizeHelper(true);

  const onSendFunction = async () => {

    let id = localStorage.getItem('resources');
    await API.post("exportresource",{
        url:window.location.origin,
        firstName: props.formik.values.firstName,
        email: props.formik.values.email,
        lastName: props.formik.values.lastName,
        schoolName: props.formik.values.schoolName,
        state: props.formik.values.state,
        role: props.formik.values.role,
        stepperSelected: props.formik.values.stepperSelected,
        resourceId: id,
    });
}

  useEffect(() => {
      
      if(readyToExport){
        props.formik.setFieldValue("readyToExport",false);
        onSendFunction();
      } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[readyToExport]);

    const ExportSchedule = () => {
      var form = document.getElementById("exportform");
      form.classList.add("active");
    }

    const GetData = async () => {
      let id = localStorage.getItem('resources');
  
      let response = await API.get(`resources/${id}`);

      console.log(id);
      
      props.formik.setFieldValue("classesPerTeacher",response.data.classesPerTeacher);
      props.formik.setFieldValue("classesPerStudent",response.data.classesPerStudent);
      props.formik.setFieldValue("totalStudentEnrollment",response.data.totalStudentEnrollment);
      props.formik.setFieldValue("modifiableFTE",response.data.modifiableFTE);
      props.formik.setFieldValue("modifiableClassSize",response.data.modifiableClassSize);
      props.formik.setFieldValue("schoolFTE",response.data.schoolFTE);
      props.formik.setFieldValue("schoolClassSize",response.data.schoolClassSize);
  
      const selectedSchedules = [];

      // eslint-disable-next-line no-negated-condition
      let comparedSchedules = (response.data.comparedSchedules !== null && response.data.comparedSchedules !== undefined)? response.data.comparedSchedules.map(schedule => schedule.split("-").join(" of ")) : [];
      comparedSchedules.forEach(schedule => {
      for (let index = 0; index < scheduleOptions.length; index++) {
        if(scheduleOptions[index].name === schedule){
          scheduleOptions[index].selected = true;
          selectedSchedules.push(scheduleOptions[index]);
          break;
        }
        
      }
      });

      props.formik.setFieldValue('selectedSchedules', selectedSchedules);
  
      props.formik.setFieldValue("scheduleOptions",scheduleOptions);
    }
  
    useEffect(() => {
      
      GetData();

      // eslint-disable-next-line no-negated-condition
      if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
        props.formik.setFieldValue("operatingSystem",'mac');
      } else {
        props.formik.setFieldValue("operatingSystem",'pc');
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
 
    
    return (
      <div id="optimizer" className="optimizer">
        <div className="sticky-top">
          <ExportForm subText="to receive a PDF of these base schedules in your inbox" />
          <ThankYouWindow 
            subText="You&apos;ll receive a PDF of this report in your inbox shortly." 
            restartText="No, I want to start a new Base Schedule Comparison" 
            restartRoute="/wizard" 
            otherToolText="We also have a free tool that helps school leaders calculate their <br> instructional minutes while experimenting with their bell schedule." 
            otherAppBtnText= "Yes! I want to experiment with my Bell Schedule" 
            tryAppText="Would you like to try our Bell Schedule Builder?" 
            otherAppUrl={process.env.REACT_APP_BELL_SCHEDULER_URL}
          />

          <OptimizerHeader />
      
          <div className="optimizer_page_text">
            <b>Add base schedules</b> that you want to compare.<br />
            <b>Then adjust class size or FTE</b> and see the impact of those adjustments across the base
            schedules you have selected.<br />
          </div>

          <ComparisonHeader />
        </div>

        <OptimizerBoard />

        <div className="line" />
        <FunctionalButton imgpath={ExportImage} className="export_button button_type_a mx-auto" text="Export Report" onclickhandler={ExportSchedule} />
        
      </div>
    );
};

Optimizer.propTypes = {
  formik: PropTypes.object.isRequired
};

export default connect(Optimizer);
