import React from 'react';
import './ClassSizeFTE.scss';
import PropTypes from 'prop-types';
import { connect } from "formik";
import AnimatedNumber from 'react-animated-number';
import GreenArrowDown from '../../assets/green_arrow_down.svg'
import OrangeArrowUp from '../../assets/orange_arrow_up.svg'

 class ClassSizeFTE extends React.PureComponent {


  constructor(props) {
    super(props);

    this.state = {
      description: props.description,
      selected: props.selected,
      currentFTE: props.currentFTE,
      currentClassSize: props.currentClassSize,
      schoolFTE: props.schoolFTE,
      schoolClassSize: props.schoolClassSize,
      stepperSelected:props.stepperSelected,
      columnPosition: props.columnPosition,
      numberEasingDuration: props.numberEasingDuration,
      isPDFView: props.isPDFView,
    }
    
  }



  numberWithoutCommas(x) {
    if (x === null) return '';
    x = x.toString();
    x = x.split(",");
    x = x.join("");
    return x;
  }


   numberWithCommas(x) {
    x = this.numberWithoutCommas(x);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  componentDidUpdate(prevProps, prevState) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        description: this.props.description,
        selected: this.props.selected,
        currentFTE: this.props.currentFTE,
        currentClassSize: this.props.currentClassSize,
        stepperSelected:this.props.stepperSelected,
        columnPosition: this.props.columnPosition,
        schoolFTE: this.props.schoolFTE,
        schoolClassSize: this.props.schoolClassSize,
        numberEasingDuration: this.props.numberEasingDuration,
        isPDFView: this.props.isPDFView,
      });
  }



  // eslint-disable-next-line complexity
  render() {
    
    return <div className="container classsizefte">
       
          <div className="col  center"> 
              <div className="row classsizefte_text">
                {(this.state.description === "true")?      
                  (this.state.columnPosition === "left")?
                    (this.state.isPDFView === "true" && this.state.stepperSelected === "FTEstepperSelected")?
                    "CLASS SIZE  NEEDED"
                    :
                    "CLASS SIZE"
                  :
                  (this.state.columnPosition === "right")?
                    (this.state.isPDFView === "true" && this.state.stepperSelected === "ClassSizestepperSelected")?
                    "FTE NEEDED"
                    :
                    "FTE" 
                    :
                    ""
                :
                ""}
              </div>
            
            <div  className="center classsize_fte_contents"> 
                  {(this.state.selected === "false" && this.state.isPDFView === "false")?
                    (this.state.stepperSelected === "FTEstepperSelected") ? 
                    <div className="noneselectednumber-properties">{Math.ceil(this.state.currentFTE)}</div>
                    :
                    <div className="noneselectednumber-properties">{Math.ceil(this.state.currentClassSize)}</div>

                  :
                  
                    <div className="row centercomponent">
                        {
                        (this.state.isPDFView === "true" && this.state.stepperSelected === "FTEstepperSelected" && this.state.columnPosition === "right")?
                            (Number(this.state.currentFTE) <= Number(this.state.schoolFTE)) ?
                            <img  src={GreenArrowDown} alt=""></img>
                            :
                            <img  src={OrangeArrowUp} alt=""></img>
                        :
                        (this.state.isPDFView === "true" && this.state.stepperSelected === "ClassSizestepperSelected" && this.state.columnPosition === "left")?
                            (Number(this.state.currentClassSize) <= Number(this.state.schoolClassSize)) ?
                            <img  src={GreenArrowDown} alt=""></img>
                            :
                            <img  src={OrangeArrowUp} alt=""></img>
                        :

                        (this.state.stepperSelected === "FTEstepperSelected") ?
                              (Number(this.state.currentClassSize) <= Number(this.state.schoolClassSize)) ?
                                <img  src={GreenArrowDown} alt=""></img>
                                :
                                <img  src={OrangeArrowUp} alt=""></img>
                              :
                              (this.state.stepperSelected === "ClassSizestepperSelected") ? 
                                (Number(this.state.currentFTE) <= Number(this.state.schoolFTE)) ?
                                  <img  src={GreenArrowDown} alt=""></img>
                                  :
                                  <img  src={OrangeArrowUp} alt=""></img>
                              : 
                              <img  src={GreenArrowDown} alt=""></img>
                        }
                        
                        &nbsp;&nbsp;
                       
                       
                        <AnimatedNumber
                          className= {
                            (this.state.isPDFView === "true" && this.state.stepperSelected === "FTEstepperSelected" && this.state.columnPosition === "right")?
                                (Number(this.state.currentFTE) <= Number(this.state.schoolFTE)) ?
                                "selectednumber-properties good"
                                :
                                "selectednumber-properties bad"
                            :
                            (this.state.isPDFView === "true" && this.state.stepperSelected === "ClassSizestepperSelected" && this.state.columnPosition === "left")?
                                (Number(this.state.currentClassSize) <= Number(this.state.schoolClassSize)) ?
                                "selectednumber-properties good"
                                :
                                "selectednumber-properties bad"
                            :

                            (this.state.stepperSelected === "FTEstepperSelected") ?
                              (Number(this.state.currentClassSize) <= Number(this.state.schoolClassSize)) ?
                                "selectednumber-properties good"
                                :
                                "selectednumber-properties bad"
                              :
                              (this.state.stepperSelected === "ClassSizestepperSelected") ? 
                                (Number(this.state.currentFTE) <= Number(this.state.schoolFTE)) ?
                                  "selectednumber-properties good"
                                  :
                                  "selectednumber-properties bad"
                              : 
                              "noneselectednumber-properties"
                            }

                          value={
                            (this.state.isPDFView === "true")?
                              (this.state.columnPosition === "left") ?
                                Number(this.state.currentClassSize) 
                              :
                              (this.state.columnPosition === "right") ? 
                                  Number(this.state.currentFTE)
                                  : 
                                  0
                            :
                            
                            (this.state.stepperSelected === "FTEstepperSelected") ?
                              Number(this.state.currentClassSize) 
                              :
                              (this.state.stepperSelected === "ClassSizestepperSelected") ? 
                              Number(this.state.currentFTE)
                                : 
                                0
                          }
                          duration={this.state.numberEasingDuration}
                          formatValue={n =>`${this.numberWithCommas(Math.ceil(n))}`} 
                        />
                      
                      <div className="numberdifference">
                        (
                          {

                            (this.state.isPDFView === "true")?
                              (this.state.columnPosition === "left") ?
                                
                                (Number(this.state.currentClassSize) <= Number(this.state.schoolClassSize))? 
                                  Number(this.state.currentClassSize) - Number(this.state.schoolClassSize)
                                :
                                `+${Number(this.state.currentClassSize) - Number(this.state.schoolClassSize)}`

                              :
                              (this.state.columnPosition === "right") ? 
                                (Number(this.state.currentFTE) <= Number(this.state.schoolFTE))? 
                                  Number(this.state.currentFTE) - Number(this.state.schoolFTE)
                                  :
                                  `+${Number(this.state.currentFTE) - Number(this.state.schoolFTE)}`
                                  :0
                            :

                            (this.state.stepperSelected === "FTEstepperSelected") ?
                              
                              (Number(this.state.currentClassSize) <= Number(this.state.schoolClassSize))? 
                                Number(this.state.currentClassSize) - Number(this.state.schoolClassSize)
                              :
                              `+${Number(this.state.currentClassSize) - Number(this.state.schoolClassSize)}` 
                              
                            :
                            (this.state.stepperSelected === "ClassSizestepperSelected") ? 
                              (Number(this.state.currentFTE) <= Number(this.state.schoolFTE))? 
                                  Number(this.state.currentFTE) - Number(this.state.schoolFTE)
                              :
                              `+${Number(this.state.currentFTE) - Number(this.state.schoolFTE)}` 
                              : 
                              0

                          }
                        )
                      </div>
                    </div>           
                  }
            </div>
          </div>
        </div>
  }
}

ClassSizeFTE.defaultProps = {
  description: "",
  selected: "true", 
  currentClassSize: -1,
  currentFTE: -1,
  numberEasingDuration: 1000,
  isPDFView: "false"
};

ClassSizeFTE.propTypes = {
  description: PropTypes.string,
  currentFTE: PropTypes.number,
  currentClassSize: PropTypes.number,
  schoolFTE: PropTypes.number.isRequired,
  schoolClassSize: PropTypes.number.isRequired,
  stepperSelected:PropTypes.string.isRequired,
  selected: PropTypes.string,
  columnPosition: PropTypes.string.isRequired,
  numberEasingDuration: PropTypes.number,
  isPDFView: PropTypes.string
};

export default connect(ClassSizeFTE);
