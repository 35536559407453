import React from 'react';
import { connect, Field } from "formik";
import './AdjustComponent.scss';
import FunctionalButton from '@abl/components/src/FunctionalButton/FunctionalButton';
import API from '@abl/components/src/api/api';
import PropTypes from 'prop-types';


const AdjustComponent = (props) => {
  const { text, value, name, stepperSelected, max, pressingTimer} = props;
  const {operatingSystem} = props.formik.values;

  let doneTypingInterval = 1000;
  
 

  const setValue = (newValue) => {
    props.formik.setFieldValue("stepperSelected", stepperSelected);
    props.formik.setFieldValue(name, newValue);

  
  }
  
  const updateStepperValue = async (newvalue) =>{ // Executes when 1 sec is passed after pressing the stepper, then it updates the value to backend.
     
    let id = localStorage.getItem('resources');
   
    await API.put(`resources/${id}`, {
          id: id,    
          "modifiableFTE": (name==="modifiableFTE")? newvalue:  props.formik.values.modifiableFTE
    });
        

    await API.put(`resources/${id}`, {
          id: id,    
          "modifiableClassSize": (name==="modifiableClassSize")? newvalue:  props.formik.values.modifiableClassSize
    });
        console.log(name+" updated");

  }


  const add = () => {
    
    let newValue = (value === max ? max-1 : value) + 1;
    setValue(newValue)
   
    

    clearTimeout(pressingTimer);
    props.formik.setFieldValue("pressingTimer",setTimeout(() => updateStepperValue(newValue), doneTypingInterval));
  };

  const sub = () => {
  
    let newValue = (value === 1 ? 2 : value) - 1;
    setValue(newValue)
    

    clearTimeout(pressingTimer);
    props.formik.setFieldValue("pressingTimer",setTimeout(() => updateStepperValue(newValue), doneTypingInterval));
  };

 

  return (
    <div className={`adjust-component ${(props.formik.values.stepperSelected === stepperSelected ? 'focus-top' : '')}`}>
      <div className="content">
        <div className="text">{text}</div>
        <div className="d-flex">
          <Field type="number" name={name} className="adjust"
          onChange={(e) => {
            (e.currentTarget.value < 1 || e.currentTarget.value > max ? e.preventDefault() : setValue(e.currentTarget.value));
          }} />
          <div>                                                                    
            <FunctionalButton id="inc-button" text={(operatingSystem==='mac')?'▲':'▴'} className={(operatingSystem==='mac')?'spinner-button row macOSarrows':'spinner-button row'} onclickhandler={add} />
            <FunctionalButton id="dec-button" text={(operatingSystem==='mac')?'▼':'▾'} className={(operatingSystem==='mac')?'spinner-button row macOSarrows':'spinner-button row'} onclickhandler={sub} />
          </div>
        </div>

      </div>
      
    </div>
  )
};

AdjustComponent.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  stepperSelected: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  max: PropTypes.number.isRequired,
  pressingTimer: PropTypes.number.isRequired,
}

export default connect(AdjustComponent);