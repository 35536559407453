import React, { useEffect } from "react";
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";
import BasicFormField from "@abl/components/src/BasicFormField/BasicFormField";
import PropTypes from 'prop-types';
import { connect } from "formik";
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';


const StepOne = (props) => {
  useResizeHelper(false);
  const { classesPerTeacher, classesPerStudent } = props.formik.values;
  let tabIndex = 10;


  useEffect(() => {
    var buttons = document.getElementById("next_btn");
    const errorMen = document.getElementById("error_teacher_student");
    let { classesPerTeacher, classesPerStudent } = props.formik.values.errors;

    if (Number(props.formik.values.classesPerTeacher) > Number(props.formik.values.classesPerStudent)
      && (props.formik.values.classesPerTeacher !== '' && props.formik.values.classesPerStudent !== '')) {
      errorMen.classList.remove("invisible");
    }

    if (classesPerTeacher || classesPerStudent || Number(props.formik.values.classesPerTeacher) > Number(props.formik.values.classesPerStudent) 
      || props.formik.values.classesPerTeacher === '' || props.formik.values.classesPerStudent === '') {
      buttons.classList.add("disabled");
    }
    else {
      buttons.classList.remove("disabled");
      errorMen.classList.add("invisible");
    }
    
  }, [props.formik.values]);

  return (
    <div className="stepone">
      <h1 className="pageFirstTitle">Determine Your &quot;Base Schedule&quot;</h1>
      

      <div className="centered_form">
        <BasicFormField
          tabIndex={tabIndex++}
          fieldName='classesPerTeacher'
          labelText='# OF CLASSES A TYPICAL TEACHER TEACHES'
          backgroundText={`period${classesPerTeacher === '1' ? '' : 's' }`}
          type='text'
          optional="true"
          displayOptional="false"
          errorMsg="Must be between 1 and 30"
          minValue={1}
          maxValue={30}
          onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
        />
        <BasicFormField
          tabIndex={tabIndex++}
          fieldName='classesPerStudent'
          labelText='# OF CLASSES A TYPICAL STUDENT TAKES'
          backgroundText={`period${classesPerStudent === '1' ? '' : 's' }`}
          type='text'
          optional="true"
          displayOptional="false"
          errorMsg="Must be between 1 and 30"
          minValue={1}
          maxValue={30}
          onKeyPressFunction={(event) => { if (event.key < '0' || event.key > '9' || event.target.value.length > 8) event.preventDefault() }}
        />
        <div id="error_teacher_student" className="invisible">The number of classes a teachers teaches must be less or equal than the number of classes a student takes</div>
      </div>

      <PageChangeButtons tabIndex={tabIndex} nextRoute="/wizard/two" />
    </div>
  );
}

StepOne.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default connect(StepOne);
