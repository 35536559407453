import React from "react";
import InformativeBox from "../../../components/InformativeBox/InformativeBox"; 
import PageChangeButtons from "@abl/components/src/PageChangeButtons/PageChangeButtons";
import { connect } from "formik";
import PropTypes from 'prop-types';
import useResizeHelper from '@abl/components/src/hooks/useResizeHelper';

const StepTwo = (props) => {
  useResizeHelper(false);
  
    return (
      <div className="steptwo">
        <div  className="informativeboxcomponent">   
            <InformativeBox classesPerTeacher={Number(props.formik.values.classesPerTeacher)} classesPerStudent={Number(props.formik.values.classesPerStudent)} />
        </div>
       
        <PageChangeButtons backRoute="/wizard/one" nextRoute="/wizard/three" />
        
      </div>
    );
  
}

StepTwo.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default connect(StepTwo);
